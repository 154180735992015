<template>
  <div class="row">
    <b-container fluid>
      <b-card
          no-body
          class="mt-md-1"
      >
        <b-overlay
            :show="isLoading"
            rounded="sm"
        >
          <div class="m-1">
            <b-row>
              <b-col
                  cols="8"
                  md="8"
              >
                <h3>{{ modalTitle }}</h3>
              </b-col>
              <b-col
                  cols="4"
                  md="4"
              >
                <div class="text-right">
                  <b-button
                      v-b-modal.credentialConditionSaveModal
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      size="sm"
                      class="ml-1"
                      @click="onCreateCredentialCondition"
                  >
                    <span

                        class="text-nowrap text-right d-none d-lg-block"
                    >
                      <feather-icon icon="PlusIcon" /> Thêm mới
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                    mode="remote"
                    class="my-table"
                    row-style-class="vgt-row"
                    style-class="vgt-table striped bordered"
                    :columns="columns"
                    :rows="credentialConditionsDisplay"
                    :pagination-options="paginationOptions"
                    :total-rows="totalRows"
                    :line-numbers="true"
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                >
                  <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                  >Không có bản ghi nào</div>
                  <template
                      slot="table-row"
                      slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    <span
                        v-if="props.column.field === 'action'"
                        class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                            v-b-modal.credentialConditionSaveModal
                            class="btn-icon"
                            variant="primary"
                            size="sm"
                            @click="onUpdateCredentialCondition(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>

                        <b-button
                            class="btn-icon"
                            variant="danger"
                            size="sm"
                            @click="onDeleteCredentialCondition(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>

                      </b-button-group>
                    </span>
                  </template>
                  <!-- pagination -->
                  <template
                      slot="pagination-bottom"
                      slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                            v-model="filter.itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.onPerPageChange({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                            :value="1"
                            :total-rows="totalRows"
                            :per-page="filter.itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.onPageChange({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
      <CredentialConditionSave
          ref="credentialConditionSaveModal"
          :item="currentCredentialCondition"
          :current-credential-id="currentCredentialId"
          @succeed="onSucceed"
      />
    </b-container>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { getUser } from '@/auth/utils'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CredentialConditionSave from '@/views/credential-condition/CredentialConditonSave.vue'
export default {
  name: 'CredentialCondition',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    CredentialConditionSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        credentialId: this.dataSend.id,
      },
      modalTitle: `Danh sách điều kiện đạt của [${this.dataSend.name}]`,
      currentCredentialCondition: undefined,
      currentCredentialId: this.dataSend.id,
      credentialConditionsDisplay: [],
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên',
          field: 'name',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Loại học phần',
          field: 'subjectType',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Khung thời gian triển khai',
          field: 'certificateImplement',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số lượng môn học tối thiểu cần đạt',
          field: 'minSubject',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Điểm tối thểu cần đạt',
          field: 'minScore',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'credentialCondition/statuses',
      totalRows: 'credentialCondition/totalRows',
      subjectTypes: 'credentialCondition/subjectTypes',
      credentialConditions: 'credentialCondition/credentialConditions',
    }),
  },
  async created() {
    this.loading = true
    try {
      await Promise.all([
        this.readCredentialConditions(this.filter),
        this.begin(),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
    await this.getCredentialConditionFromStore()
  },
  methods: {
    ...mapActions({
      begin: 'credentialCondition/begin',
      readCredentialConditions: 'credentialCondition/readCredentialConditions',
      deleteCredentialCondition: 'credentialCondition/deleteCredentialCondition',
    }),
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    onCreateCredentialCondition() {
      this.currentCredentialCondition = undefined
    },
    onUpdateCredentialCondition(row) {
      const {
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentCredentialCondition = rest
    },
    onListSubjectConditions(row) {
      const {
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentCredentialCondition = rest
    },
    onDeleteCredentialCondition(credentialCondition) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xóa?',
        text: `Xóa thời gian ${credentialCondition.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          const response = await this.deleteCredentialCondition(credentialCondition.id)
          const { isSuccessful, message } = response
          if (response) {
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              try {
                await this.getCredentialConditionFromStore()
              } catch (e) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `[${e.code}] ${e.message}`,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
          try {
            await this.deleteCredentialCondition(credentialCondition.id)
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
      this.isLoading = false
    },
    async getCredentialConditionFromStore() {
      this.isLoading = true
      try {
        await this.readCredentialConditions(this.filter)
        this.credentialConditionsDisplay = this.credentialConditions
        for (let i = 0; i < this.credentialConditionsDisplay.length; i++) {
          this.credentialConditionsDisplay[i].subjectConditionId = []
          if (this.credentialConditionsDisplay[i].subjectConditionIds !== null) {
            this.credentialConditionsDisplay[i].subjectConditionId = this.credentialConditionsDisplay[i]
              .subjectConditionIds
              .split(',')
              .map(item => +item)
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSucceed() {
      await this.getCredentialConditionFromStore()
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getCredentialConditionFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getCredentialConditionFromStore()
    },
  },
}
</script>

<style scoped lang="scss">
</style>

<template>
  <div class="row">
    <b-container fluid>
      <b-card
          no-body
          class="mt-md-1"
      >
        <b-overlay
            :show="isLoading"
            rounded="sm"
        >
          <div class="m-1">
            <b-row>
              <b-col
                  cols="8"
                  md="8"
              >
                <h3>{{ modalTitle }}</h3>
              </b-col>
              <b-col
                  cols="4"
                  md="4"
              >
                <div class="text-right">
                  <b-button
                      v-b-modal.credentialImplementSaveModal
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      size="sm"
                      class="ml-1"
                      @click="onCreateCredentialImplement"
                  >
                    <span

                        class="text-nowrap text-right d-none d-lg-block"
                    >
                      <feather-icon icon="PlusIcon" /> Thêm mới
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                    mode="remote"
                    class="my-table"
                    row-style-class="vgt-row"
                    style-class="vgt-table striped bordered"
                    :columns="columns"
                    :rows="credentialImplements"
                    :pagination-options="paginationOptions"
                    :total-rows="totalRows"
                    :line-numbers="true"
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                >
                  <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                  >Không có bản ghi nào</div>
                  <template
                      slot="table-row"
                      slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'timeImplement' || props.column.field === 'timeEnd'">
        {{ formatDate(props.formattedRow[props.column.field]) }}
      </span>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    <span
                        v-if="props.column.field === 'action'"
                        class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                            v-b-modal.credentialImplementSaveModal
                            class="btn-icon"
                            variant="primary"
                            size="sm"
                            @click="onUpdateCredentialImplement(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>

                        <b-button
                            class="btn-icon"
                            variant="danger"
                            size="sm"
                            @click="onDeleteCredentialImplement(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                  </template>
                  <!-- pagination -->
                  <template
                      slot="pagination-bottom"
                      slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                            v-model="filter.itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.onPerPageChange({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                            :value="1"
                            :total-rows="totalRows"
                            :per-page="filter.itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.onPageChange({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
      <CredentialImplementSave
          ref="credentialImplementSaveModal"
          :item="currentCredentialImplement"
          :current-credential-id="currentCredentialId"
          @succeed="onSucceed"
      />
    </b-container>

  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { getUser } from '@/auth/utils'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CredentialImplementSave from '@/views/credential-implement/CredentialImplementSave.vue'
import credentialImplement from '@/store/credential-implement'

export default {
  name: 'CredentialImplement',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    CredentialImplementSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        // organizationId: getUser().orgId,
        currentPage: 1,
        itemsPerPage: 10,
        id: this.dataSend.id || this.$route.params.id,
      },
      modalTitle: `Danh sách khung thời gian triển khai của [${this.dataSend.name}]`,
      currentCredentialImplement: undefined,
      currentCredentialId: this.dataSend.id,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      credentialImplementTest: [
        {
          id: 1,
          name: 'Đăng ký tốt nghiệp đợt 1 kỳ 20242',
          year: '2024',
          time_implement: '2024-05-20',
          time_end: '2024-06-20',
          status: 1,
        },
        {
          id: 2,
          name: 'Thời gian tổng kết học kỳ 20242',
          year: '2024',
          time_implement: '2024-06-20',
          time_end: '2024-06-30',
          status: 0,
        },
      ],
      columns:  [
        {
          label: 'Khung thời gian',
          field: 'name',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thời gian bắt đầu/1 đợt',
          field: 'timeImplement',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thời gian kết thúc/1 đợt',
          field: 'timeEnd',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          filterOptions: {
            enable: true,
            trigger: 'enter',
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'credentialImplement/statuses',
      credentialImplements: 'credentialImplement/credentialImplements',
      totalRows: 'credentialImplement/totalRows',
    }),
  },
  async created() {
    this.loading = true
    try {
      await this.readCredentialImplements(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readCredentialImplements: 'credentialImplement/readCredentialImplements',
      deleteCredentialImplement: 'credentialImplement/deleteCredentialImplement',
    }),
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    onCreateCredentialImplement() {
      this.currentCredentialImplement = undefined
    },
    onUpdateCredentialImplement(row) {
      const {
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentCredentialImplement = rest
    },
    onDeleteCredentialImplement(credentialImplement) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xóa?',
        text: `Xóa thời gian ${credentialImplement.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          const response = await this.deleteCredentialImplement(credentialImplement.id)
          const { isSuccessful, message } = response
          if (response) {
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              try {
                await this.readCredentialImplements(this.filter)
              } catch (e) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `[${e.code}] ${e.message}`,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
          try {
            await this.deleteCredentialImplement(credentialImplement.id)
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async getCredentialImplementFromStore() {
      this.isLoading = true
      try {
        await this.readCredentialImplements(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSucceed() {
      await this.getCredentialImplementFromStore()
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getCredentialImplementFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage,  })
      await this.getCredentialImplementFromStore()
    },
    formatDate(dateString) {
      // dateString có thể là 'yyyy/mm/dd'
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}`;
    },
  },
}
</script>

<style scoped lang="scss">

</style>

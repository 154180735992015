<template>
  <div>
    <b-container fluid>
      <b-card>
        <b-overlay>
          <b-row>
            <b-col
                cols="12"
            >
              <b-row>
                <b-col
                    cols="12"
                    class="text-right"
                >
                  <b-button-group>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        v-b-modal.credentialSaveModal
                        class=""
                        variant="success"
                        @click="createCredential"
                    >
                      <feather-icon icon="PlusIcon" />
                      Thêm mới
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="m-2">
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                    mode="remote"
                    class="my-table"
                    row-style-class="vgt-row"
                    style-class="vgt-table striped bordered"
                    :columns="columns"
                    :rows="credentials"
                    :pagination-options="paginationOptions"
                    :total-rows="totalRows"
                    :line-numbers="true"
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                >
                  <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                      slot="table-row"
                      slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    <span
                        v-if="props.column.field === 'action'"
                        class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                            v-b-modal.credentialSaveModal
                            class="btn-icon"
                            variant="primary"
                            @click="updateCredential(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-dropdown

                            variant="success"
                        >
                          <b-dropdown-item
                              class="btn-icon"
                              size="sm"
                              @click="onListCredentialSample(props.row)"
                          >
                            <feather-icon icon="FileIcon" />
                            Mẫu văn bằng/chứng chỉ
                          </b-dropdown-item>

                          <b-dropdown-item
                              class="btn-icon"
                              size="sm"
                              @click="onListCredentialConditions(props.row)"
                          >
                            <feather-icon icon="ToolIcon" />
                            Điều kiện đạt
                          </b-dropdown-item>

                          <b-dropdown-item
                              class="btn-icon"
                              size="sm"
                              @click="onListCredentialProcessType(props.row)"
                          >
                            <feather-icon icon="MessageSquareIcon" />
                            Loại yêu cầu
                          </b-dropdown-item>

                        </b-dropdown>
                        <b-button
                            class="btn-icon"
                            variant="danger"
                            @click="onDeleteCredential(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                  </template>
                  <!-- pagination -->
                  <template
                      slot="pagination-bottom"
                      slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                            v-model="filter.itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                            :value="1"
                            :total-rows="totalRows"
                            :per-page="filter.itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>

              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
      <CredentialSave
          ref="credentialSaveModal"
          :item="currentCredential"
          @succeed="onSucceed"
      />
    </b-container>
    <b-modal
        id="modal-list-credential-implement"
        ref="modal-list-credential-implement"
        ok-title="Xác nhận"
        cancel-title="Hủy"
        centered
        size="xl"
        :hide-header="true"
        @ok="onSucceed"
    >
      <CredentialImplement :data-send="currentCredential" />
    </b-modal>
    <b-modal
        id="modal-list-credential-sample"
        ref="modal-list-credential-sample"
        ok-title="Xác nhận"
        cancel-title="Hủy"
        centered
        size="xl"
        :hide-header="true"
        @ok="onSucceed"
    >
      <CredentialSample :data-send="currentCredential" />
    </b-modal>
    <b-modal
        id="modal-list-credential-condition"
        ref="modal-list-credential-condition"
        ok-title="Xác nhận"
        cancel-title="Hủy"
        centered
        size="xl"
        :hide-header="true"
        @ok="onSucceed"
    >
      <CredentialCondition :data-send="currentCredential" />
    </b-modal>
    <b-modal
        id="modal-list-credential-process-type"
        ref="modal-list-credential-process-type"
        ok-title="Xác nhận"
        cancel-title="Hủy"
        centered
        size="xl"
        :hide-header="true"
        @ok="onSucceed"
    >
      <CredentialProcessType :data-send="currentCredential" />
    </b-modal>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BDropdown,
  BButtonGroup,
  BDropdownItem,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BFormGroup, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import CredentialImplement from '@/views/credential-implement/CredentialImplement.vue'
import moment from 'moment/moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CredentialSave from '@/views/credential/CredentialSave.vue'
import CredentialSample from '@/views/credential-sample/CredentialSample.vue'
import CredentialCondition from '@/views/credential-condition/CredentialCondition.vue'
import CredentialProcessType from '@/views/credential-process-types/CredentialProcessType.vue'

export default {
  name: 'Credential',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    CredentialProcessType,
    CredentialSample,
    CredentialSave,
    CredentialImplement,
    CredentialCondition,
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
      },
      currentCredential: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      credentialsTest: [
        {
          id: 1,
          name: 'Chứng chỉ ngoại ngữ',
          type: 'Chứng chỉ môn học',
          year: 2024,
          status: 2,
          time_end: '2024-06-20',
          description: 'test',
          time_implement: '2024-05-20',
        },
        {
          id: 3,
          name: 'Bằng tốt nghiệp cử nhân',
          type: 'Bằng cử nhân',
          year: 2024,
          status: 1,
          time_end: null,
          description: 'test',
          time_implement: null,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      statusesCredential: 'credential/statusesCredential',
      credentials: 'credential/credentials',
      totalRows: 'credential/totalRows',
    }),
    columns() {
      return [
        {
          label: 'Tên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
        },
        {
          label: 'Phân loại',
          field: 'type',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.readCredentials(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readCredentials: 'credential/readCredentials',
      deleteCredential: 'credential/deleteCredential',
    }),
    createCredential() {
      this.currentCredential = undefined
    },
    updateCredential(row) {
      const {
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentCredential = rest
    },
    onDeleteCredential(credential) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xóa?',
        text: `Xóa ${credential.description}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          const response = await this.deleteCredential(credential.id)
          const { isSuccessful, message } = response
          if (response) {
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              try {
                await this.readCredentials(this.filter)
              } catch (e) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `[${e.code}] ${e.message}`,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
          try {
            await this.deleteCredential(credential.id)
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    getStatusName(id) {
      return this.statusesCredential.find(status => status.value === id).label
    },
    onListCredentialImplement(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentCredential = rest
      this.$refs['modal-list-credential-implement'].show()
    },
    onListCredentialSample(row) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentCredential = rest
      this.$refs['modal-list-credential-sample'].show()
    },
    onListCredentialConditions(row) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentCredential = rest
      this.$refs['modal-list-credential-condition'].show()
    },
    onListCredentialProcessType(row) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentCredential = rest
      this.$refs['modal-list-credential-process-type'].show()
    },
    async getCredentialsFromStore() {
      this.isLoading = true
      try {
        await this.readCredentials(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getProgrammesFromStore()
    },
    async onSucceed() {
      await this.getCredentialsFromStore()
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getCredentialsFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getCredentialsFromStore()
    },
  },

}
</script>

<style scoped lang="scss">
</style>
